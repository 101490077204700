const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 申请补退
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const buyerWhiteOrderRepair = (data) => {
    pParameter = {
        method: 'post',
        urlSuffix: '/buyer/dom/manage/repair',
        data: data,
        enable_error_alert: false // 临时隐藏
    }
    return __request(pParameter)
};
module.exports = buyerWhiteOrderRepair;
