import {awaitWrap} from "@/page/distributor/train/front/common/unit";
import upLoadImg from "@/lib/data-service/default/web_common_upload_aptitudeSingleImage";
import flight_domestic_buyer_white_order_repair
    from '@/lib/data-service/flight/flight_domestic_buyer_white_order_repair'
import buyer_dom_manage_applyOrderRepair from '@/lib/data-service/flight/buyer_dom_manage_applyOrderRepair'
import buyer_dom_manage_applyOrderRepairReason from '@/lib/data-service/flight/buyer_dom_manage_applyOrderRepairReason'
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
    data() {
        const checkRepAppAmount = function (rule, value, callback) {
            let showNum = parseFloat(value)
            let re = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
            if (showNum !== NaN) {
                if (re.test(showNum)) {
                    callback()
                } else {
                    callback(new Error('请输入不低于0且最多可带两位小数'))
                }
            } else {
                callback(new Error('请输入不低于0且最多可带两位小数'))
            }
        }
        return {
            ossClient: new OssClient(),
            detailLoading: false,
            detail: {},
            defaultText: "------",
            handleParams: {
                orderNo: '',//订单号
                passengerList: [],
                repType: 1,//申退类型 1-补差价
                repAppAmount: '',//申退金额
                repReason: '',//申退理由
                repUrl: '',//申退附件
                remark: '',//备注
            },
            segmentList: [],
            repTypeList: [
                {
                    value: 1,
                    label: '退差价'
                }
            ],
            repReasonList: [],
            selectedPsgNos: [],
            fileImg: [],
            rules: {
                repType: {required: true, message: "请选择申退类型", trigger: "blur"},
                repAppAmount: [
                    {required: true, message: "请输入申退金额", trigger: "blur"},
                    {validator: checkRepAppAmount, trigger: 'blur'}],
                repReason: {required: true, message: "请选择申退理由", trigger: "blur"}
            }
        }
    },
    components: {RefundChangeLabel},
    activated() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.reactForm();
            this.orderNo = this.$route.query.orderNo;
            this.handleParams.orderNo = this.$route.query.orderNo;
            this.getApplyReason();
            this.getDetail();
        },
        reactForm() {
            this.detail = {};
            this.segmentList = [];
            this.handleParams = {
                orderNo: '',//订单号
                passengerList: [],
                repType: 1,//申退类型 1-补差价
                repAppAmount: '',//申退金额
                repReason: '',//申退理由
                repUrl: '',//申退附件
                remark: '',//备注
            }
        },
        //获取详情
        async getDetail() {
            this.detailLoading = true
            let [err, res] = await awaitWrap(flight_domestic_buyer_white_order_repair({orderNo: this.orderNo}))
            if (err) {
                this.detailLoading = false
                this.$alert(err.data.msg, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1);
                    }
                });
                return
            }
            this.detail = res.repairRes;
            this.detailLoading = false
        },

        //获取申退理由枚举值
        async getApplyReason() {
            let res = await buyer_dom_manage_applyOrderRepairReason();
            this.repReasonList = res.res;
        },

        //选择申请补退的航段
        selectFlight(val, index) {
            this.segmentList[index] = val;
            let passengerList = (arr = []) => {
                if (!arr instanceof Array) return;
                return arr.map(item => {
                    let list = {
                        psgNo: item[0].psgId,
                        segmentNoList: item.map(items => items.segmentId)
                    };
                    return list;
                });
            };
            this.handleParams.passengerList = passengerList(
                Object.values(this.segmentList).filter(item => item.length)
            );
        },

        //上传附件
        uploadImg(file) {
            if (this.fileImg.length >= 5) {
                this.$message({type: "warning", message: "申退附件最多上传5个文件！"});
                return;
            }
            console.log(file);
            console.log(file.file);
            this.ossClient.multipartUpload({
                file: file.file,
            }).then((res) => {
                this.fileImg.push({ url: res.url, uid: file.file.uid });
                //console.log(res.url);
                this.getUrl(this.fileImg);
            }).catch(() => {
            }).finally(() => {
            });
            /*upLoadImg(file.file).then(res => {
                this.fileImg.push({url: res.fileList[0], uid: file.file.uid});
                this.getUrl(this.fileImg);
            });*/
        },

        //删除附件
        removeImg(file) {
            this.fileImg.map((item, index) => {
                item.uid == file.uid ? this.fileImg.splice(index, 1) : "";
            });
            this.getUrl(this.fileImg);
        },

        //上传前检测附件
        detectionImg(file) {
            let reg2 = /^(\s|\S)+(jpg|png|pdf|doc|docx|xls|xlsx)+$/;
            const isLt2M = file.size / 1024 < 5 * 1024;
            if (!reg2.test(file.name)) {
                this.$message({type: "warning", message: "图片格式上传错误！"});
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 5M!");
                return false;
            }
        },

        //获取附件路径
        getUrl(val) {
            let url = val.map(item => {
                return item.url;
            });
            this.handleParams.repUrl = url.join(",");
        },

        //提交补退申请
        async handlePay() {
            if (this.handleParams.passengerList.length === 0) {
                return this.$message({type: "warning", message: "请选择航程信息", offset: 80});
            }

            this.$refs.form.validate(valid => {
                if (valid) {
                    this.detailLoading = true
                    buyer_dom_manage_applyOrderRepair(this.handleParams).then(res => {
                        this.$message({
                            type: "success",
                            message: '申请补退成功'
                        })
                        this.$router.replace({
                            name: 'air-ticket-admin-supplement-refund-order-list'
                        })
                    }).finally(() => {
                        this.detailLoading = false
                    })
                }
            });
        },

        //返回
        back() {
            this.$router.go(-1);
        }
    }
}
